import { useState, useEffect, useRef } from 'react'
import React from 'react';
import { 
  getHasPcbStatus,
  getPcbStatus,
  checkPcbUpdated,
  generatePcb,
  getPcb,
  // getGerber
} from '../models/PcbManager';
import { Stack, 
  Button,
  Box,
  Typography } from '@mui/material'
import fileDownload from 'js-file-download'
import Images from './Images'
import Gcode from './Gcode'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadIcon from '@mui/icons-material/Download'

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function Pcb(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [pcbStatus, setPcbStatus] = useState(null)
  const [pcbFailed, setPcbFailed] = useState(false)
  const [pcbWatching, setPcbWatching] = useState(false)
  const [pcbGenerated, setPcbGenerated] = useState(false)

  const [doneImages, setDoneImages] = useState(false)
  const [doneGcode, setDoneGcode] = useState(false)
  
  let pcbTimer = useRef()
  
  
  function intervalPcbStatus() {
    getPcbStatus(projectId).then(data => {
      console.log('getPcbStatus', data)
      setPcbStatus(data)
    })
  }
  
  
  function callGeneratePcb() {
    generatePcb(projectId)
    setPcbWatching(true)
  }


  function getStatusWithProgress() {
    const status = [
      "started",
      "unrouted",
      "dsn",
      "session",
      "completed"
    ]
    if (0 <= status.indexOf(pcbStatus)) {
      return (status.indexOf(pcbStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  
  useEffect(() => {
    getHasPcbStatus(projectId).then(data => {
      console.log('getHasPcbStatus', data)
      if (data === true) {
        setPcbWatching(true)
      } else {
        // status.jsonは存在しないのでSTLの生成を行う。
        callGeneratePcb()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (pcbWatching === true) {
      pcbTimer.current = setInterval(intervalPcbStatus, 3000)
    } else {
      clearInterval(pcbTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcbWatching])
  
  
  useEffect(() => {
    console.log('pcbStatus', pcbStatus)
    if (pcbStatus === "completed" || pcbStatus === "error") {
      console.log('checkPcbUpdated')
      checkPcbUpdated(projectId).then(data => {
        console.log('checkPcbUpdated ' + data)
        if (data === false) {
          // 処理は終わっているが、status.jsonはkbdata.jsonより古いのでSTLファイルは古い情報で生成されたことになる
          // なので、再度STLの生成を行う。
          setPcbGenerated(false)
          callGeneratePcb()
        } else if (data === true) {
          setPcbWatching(false)
          if (pcbStatus === "completed") {
            setPcbGenerated(true)
          } else {
            setPcbFailed(true)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcbStatus])
  
  
  useEffect(() => {
    console.log("pcbGenerated " + pcbGenerated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcbGenerated])


  useEffect(() => {
    console.log("pcb", doneImages, doneGcode, pcbGenerated)
    if (doneImages && doneGcode && pcbGenerated) {
      props.setDone(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, doneImages, doneGcode, pcbGenerated])


  // --------------------------------
  

  async function pcbHandler(event) {
    if (pcbGenerated) {
      const response = await getPcb(projectId)
      fileDownload(response.Body, 'pcb.kicad_pcb')
    }
  }
  /*
  async function gerberHandler(event) {
    if (pcbGenerated) {
      const response = await getGerber(projectId)
      fileDownload(response.Body, 'gerber.zip')
    }
  }
  */
  
  // --------------------------------
  
  return (
    <Box>
      { pcbGenerated ?
          <Stack
            spacing={2}>
            <Button onClick={pcbHandler} endIcon={<DownloadIcon />} 
              sx={{ mb: 1 }}>
              PCB
            </Button>
            <Images projectId={projectId} setDone={setDoneImages} />
            <Gcode projectId={projectId} setDone={setDoneGcode} />
          </Stack>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !pcbFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { pcbStatus && !pcbFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {pcbStatus}</Typography>
            :
              ""
            }
            <Typography>PCB</Typography>
            { pcbFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </Box>
  )
}

