import './index.css'
import React from 'react';
import { createRoot } from "react-dom/client"
import { constants } from './models/Constants'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom"
import { Amplify } from "aws-amplify"
import AwsParams from "./aws-exports"
import Root from './components/Root'
import Home from './components/Home'
import Editor from './components/Editor'
import Project from './components/Project'
import Options from './components/Options'
import Checkout from './components/Checkout'
import Order from './components/Order'
import Keymap from './components/Keymap'
import Upload from './components/Upload'
import Firm from './components/Firm'
import KeymapEdit from './components/KeymapEdit'
import FirmGen from './components/FirmGen'

Amplify.configure(AwsParams)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/editor/:projectId",
        element: <Editor />
      },
      {
        path: "/project/:projectId",
        element: <Project />
      },
      {
        path: "/options/:projectId",
        element: <Options />
      },
      {
        path: "/checkout/:projectId",
        element: <Checkout />
      }
    ]
  },
  {
    path: "/" + constants.LITE_PATH,
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/" + constants.LITE_PATH + "/editor/:projectId",
        element: <Editor />
      },
      {
        path: "/" + constants.LITE_PATH + "/project/:projectId",
        element: <Project />
      },
      {
        path: "/" + constants.LITE_PATH + "/options/:projectId",
        element: <Options />
      },
      {
        path: "/" + constants.LITE_PATH + "/checkout/:projectId",
        element: <Checkout />
      }
    ]
  },
  {
    path: "/keymap",
    element: <Keymap />,
    children: [
      {
        path: "/keymap/upload",
        element: <Upload />
      },
      {
        path: "/keymap/edit/:projectId",
        element: <KeymapEdit />
      },
      {
        path: "/keymap/firm/:projectId",
        element: <Firm />
      }
    ]
  },
  {
    path: "/firmgen",
    element: <FirmGen />
  },
  {
    path: "/order/:projectId",
    element: <Order />
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
])


createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
)
