const AwsParams = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:16d1249e-1731-4398-b86d-84952a5cb066",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_3MeHb2ISf",
    "aws_user_pools_web_client_id": "3b8st6o6dc4pfjlc4eua5ikfgt",
    "oauth": {
        "domain": "auth2.figmentresearch.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://keyboard-dev.figmentresearch.com",
        "redirectSignOut": "https://keyboard-dev.figmentresearch.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://vb3wrtn7n5ckhkx5ae3kv5pqfy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "_aws_user_files_s3_bucket": "cdkkeyboardstoragestackdev-bucket83908e77-1ijuahbc07vc3",
    "aws_user_files_s3_bucket": "cdkkeyboardstoragestackdev-bucketv2b312b3be-1pf533bt6136s",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};

export default AwsParams;
